import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function basicProductList({
                                   pageIndex,
                                   pageSize,
                                   collection,
                                   availableNow,
                                   orderByColumn,
                                   filter,
                                   search,
                                   category,
                                 }) {
  return omniFetch({
    url: config.v1Path + config.basicProductListPath,
    method: "post",
    params: {
      pageIndex,
      pageSize,
      collection,
      availableNow,
      orderByColumn,
      filter,
      search,
      category,
    }
  })
}

export function basicProductListByOccasion() {
  return omniFetch({
    url: config.v1Path + config.basicProductListByOccasionPath,
    method: "get",
    params: {}
  })
}

export function basicProductListByCollection({
                                               collection,
                                               pageIndex,
                                               pageSize,
                                               availableNow,
                                               orderByColumn,
                                               filter,
}) {
  return omniFetch({
    url: config.v1Path + config.basicProductListByCollectionPath,
    method: "post",
    params: {
      collection,
      pageIndex,
      pageSize,
      availableNow,
      orderByColumn,
      filter,
    }
  })
}

export function basicProductListByNewArrivals() {
  return omniFetch({
    url: config.v1Path + config.basicProductListByNewArrivalsPath,
    method: "get",
    params: {}
  })
}

export function basicProductListByViavia() {
  return omniFetch({
    url: config.v1Path + config.basicProductListByViaviaPath,
    method: "get",
    params: {}
  })
}

export function basicProductListByBrand({
                                          brand,
                                          pageIndex,
                                          pageSize,
                                          availableNow,
                                          orderByColumn,
                                          filter,
}) {
  return omniFetch({
    url: config.v1Path + config.basicProductListByBrandPath,
    method: "post",
    params: {
      brand,
      pageIndex,
      pageSize,
      availableNow,
      orderByColumn,
      filter,
    }
  })
}

export function basicProductListBySimilarItems({productId, pageIndex, pageSize}) {
  return omniFetch({
    url: config.v1Path + config.basicProductListBySimilarItemsPath + "/" + productId,
    method: "get",
    params: {pageIndex, pageSize}
  })
}

export function basicProductListByRecentlyViewed({productId, pageIndex, pageSize}) {
  return omniFetch({
    url: config.v1Path + config.basicProductListByRecentlyViewedPath + `${productId ? `/${productId}` : ''}`,
    method: "get",
    params: {pageIndex, pageSize}
  })
}

export function basicProductListByYouLike({productId, pageIndex, pageSize}) {
  return omniFetch({
    url: config.v1Path + config.basicProductListByYouLikePath + `${productId ? `/${productId}` : ''}`,
    method: "get",
    params: {pageIndex, pageSize}
  })
}
